<template>
  <div class="news">
    <div class="banner">
      <div class="banner_text">
        <h6 class="banner_title">新闻资讯</h6>
        <div class="line"></div>
        <p class="banner_content">NEWS</p>
      </div>
    </div>
    <div class="container">
      <div class="row" :data="currentList">
        <div class="col-sm-4 col-xs-6" v-for="(item, index) in currentList" :key="index" style="margin: 20px 0px;">
          <div class="card" @click="toPath(item)">
            <div class="card_date">
              <img :src="item.imgPhoto" class="card-img-top" alt="...">
              <!--              <div class="date">-->
              <!--                <span style="font-size: 20px;">12</span>-->
              <!--                <p style="font-size:14px;color:#B7B7B7">2023</p>-->
              <!--              </div>-->
            </div>
            <div class="card-body">
              <h5 class="card-title">{{ item.title }}</h5>
              <!--              <p class="card-text">{{ item.article }}</p>-->
              <p class="card-text"><small class="text-muted" @click="toPath(item)">MORE <img
                    src="../assets/arrow.png"></small></p>
            </div>
          </div>
        </div>
      </div>

      <div class="pageCount" style="display: flex; justify-content: flex-end;">
        <el-pagination background layout="prev, pager, next" :page-size="preView" @current-change="getList"
          :total="total">
        </el-pagination>
      </div>
    </div>
  </div>
</template>
<script>
// import {dataList} from "@/api/database";

export default {
  data() {
    return {
      pageCount: 1,  // 当前页码
      total: 21,     // 总条目数
      preView: 9,    // 每页显示的条目数
      tableData: [], // 原始数据集
      list: [
        {
          no: 19,
          title: "Kruskal - Wallis 检验后如何进行多重比较（含python代码）",
          imgPhoto: require('../assets/newsImages/new19.jpg')
        },
        {
          no: 20,
          title: "建议收藏！标准化与归一化的区别（含案例）",
          imgPhoto: require('../assets/newsImages/new20.jpg')
        },
        {
          no: 21,
          title: "专业医学论文与统计分析服务，助力您的医学研究之旅",
          imgPhoto: require('../assets/newsImages/new21.png')
        },
        {
          no: 1,
          title: "临床预测模型相关概念及应用场景",
          imgPhoto: require('../assets/newsImages/new11.jpg')
        },
        {
          no: 2,
          title: "从入门到精通：分层聚类的深度解析与应用（含R语言教程）",
          imgPhoto: require('../assets/newsImages/new10.jpg')
        },
        {
          no: 3,
          title: "突破 SLE 临床困境！公共数据库＋分层分析＋解释模型=SLE患者的亚群",
          imgPhoto: require('../assets/newsImages/new12.jpg')
        },
        {
          no: 4,
          title: "秒变数据达人！轻松利用R制作统计描述表格（含R语言实战演练）",
          imgPhoto: require('../assets/newsImages/new13.jpg')
        },
        {
          no: 5,
          title: "C-RCT集群随机对照试验（含SCI文章解读）",
          imgPhoto: require('../assets/newsImages/new17.jpg')
        },
        {
          no: 6,
          title: "高级且小众，这个科研赛道超好做！（含研究热点和方向）",
          imgPhoto: require('../assets/newsImages/new18.jpg')
        },
        {
          no: 7,
          title: "还在为偏态分布数据分析感到头疼吗？快试试这种回归吧！",
          imgPhoto: require('../assets/newsImages/new15.jpg')
        },
        {
          no: 8,
          title: "实用且通俗易懂！轻松学会线性回归分析（含R语言实践）",
          imgPhoto: require('../assets/newsImages/new14.jpg')
        },
        {
          no: 9,
          title: "全网最全总结！描述性统计分析（含R语言进阶）",
          imgPhoto: require('../assets/newsImages/new16.png')
        },
        {
          no: 10,
          title: "数据分析SPSS篇——独立样本t检验",
          imgPhoto: require('../assets/newsImages/new1.jpg')
        },
        {
          no: 11,
          title: "一篇文章！让你知道“卡方和Fisher怎么选”",
          imgPhoto: require('../assets/newsImages/new2.png')
        },
        {
          no: 12,
          title: "发SCI必看！95%置信区间估计基本方法和软件操作",
          imgPhoto: require('../assets/newsImages/new3.jpg')
        },
        {
          no: 13,
          title: "信我！！关于泊松回归看这一篇就够了！！！",
          imgPhoto: require('../assets/newsImages/new4.jpg')
        },
        {
          no: 14,
          title: "临床常用SEER数据库使用指南【超详细版】",
          imgPhoto: require('../assets/newsImages/new5.png')
        },
        {
          no: 15,
          title: "如何从“0”开始构建研究需要的CRF",
          imgPhoto: require('../assets/newsImages/new6.png')
        },
        {
          no: 16,
          title: "力推！！！专为个人医生设计的免费科研平台",
          imgPhoto: require('../assets/newsImages/new7.jpg')
        },
        {
          no: 17,
          title: "高分综述：预测模型（列线图），并不是表面上看到的那么简单",
          imgPhoto: require('../assets/newsImages/new8.jpg')
        },
        {
          no: 18,
          title: "速看！单因素方差分析含SPSS篇",
          imgPhoto: require('../assets/newsImages/new9.jpg')
        },

      ],
      currentList: []  // 当前页面需要显示的项目列表
    };
  },
  methods: {
    toPath(item) {
      // this.$router.push('/NewsDetail');
      // 将微信链接替换成你需要打开的链接
      let wechatLink = '';
      switch (item.no) {
        case 1:
          wechatLink = 'https://mp.weixin.qq.com/s/jZEzsvlJh3p3_nSSdrKwTA';
          break;
        case 2:
          wechatLink = 'https://mp.weixin.qq.com/s/drA0esvANNJdp9GQdNodWw';
          break;
        case 3:
          wechatLink = 'https://mp.weixin.qq.com/s/4WRstqBdGllgFOx3deUKUw';
          break;
        case 4:
          wechatLink = 'https://mp.weixin.qq.com/s/W6aBvb3h3nBKJYXsICVw9A';
          break;
        case 5:
          wechatLink = 'https://mp.weixin.qq.com/s/YwjZ6sadIsoDPAGuguvtvQ';
          break;
        case 6:
          wechatLink = 'https://mp.weixin.qq.com/s/jPb-w8xTpKpkK0I5M1E8Iw';
          break;
        case 7:
          wechatLink = 'https://mp.weixin.qq.com/s/sNGXUPSD0LCVx-5QKKQbcQ';
          break;
        case 8:
          wechatLink = 'https://mp.weixin.qq.com/s/mRLyY14DVHue6ZjoRCQdbQ';
          break;
        case 9:
          wechatLink = 'https://mp.weixin.qq.com/s/ZU8rceedOHA4E7deILPR9A';
          break;
        case 10:
          wechatLink = 'https://mp.weixin.qq.com/s/Wx63tG9-qq14VWlsW-7CXQ';
          break;
        case 11:
          wechatLink = 'https://mp.weixin.qq.com/s/ha8_X-eUmFAbWFM1hYiJ-Q';
          break;
        case 12:
          wechatLink = 'https://mp.weixin.qq.com/s/f6VGkW1m7gxult9Hnhv1-w';
          break;
        case 13:
          wechatLink = 'https://mp.weixin.qq.com/s/Mv4STDveuLnrG8_AAWgI5w';
          break;
        case 14:
          wechatLink = 'https://mp.weixin.qq.com/s/gCj8jgpqafpGCe5kvlkTgg';
          break;
        case 15:
          wechatLink = 'https://mp.weixin.qq.com/s/pdQC2EYl_yRxyBHTV3NKCA';
          break;
        case 16:
          wechatLink = 'https://mp.weixin.qq.com/s/IxP6aC_xDI93pmTvu4Ga7g';
          break;
        case 17:
          wechatLink = 'https://mp.weixin.qq.com/s/MSVjHMk4lskVNSC16pcN9A';
          break;
        case 18:
          wechatLink = 'https://mp.weixin.qq.com/s/1yU0B8EvO3o0lAda45cvvg';
          break;
        case 19:
          wechatLink = 'https://mp.weixin.qq.com/s/1PCbe24ec6yz29LEAkAaKA';
          break;
        case 20:
          wechatLink = 'https://mp.weixin.qq.com/s/w1j14uPejiZ614EChp46sQ';
          break;
        case 21:
          wechatLink = 'https://mp.weixin.qq.com/s/fTRVblkStGuat5yiFCYHOQ';
          break;
        default:
          return 'https://example.com/defaultLink';
      }



      // 打开新的浏览器窗口并加载微信链接
      window.open(wechatLink, '_blank');
    },
    getList(page) {
      // 根据当前页码（从1开始）计算起始和结束的索引
      const start = (page - 1) * this.preView;
      const end = start + this.preView;

      // 使用 slice 方法获取当前页的数据
      this.currentList = this.list.slice(start, end);
    }
  },
  created() {
    // 在组件创建时初始化第一页
    this.getList(this.pageCount);
  }

}


</script>
<style scoped lang="less">
/* 移动端样式 */
@media screen and (max-width: 767px) {
  .banner {
    width: 100%;
    height: 320px;
    background-image: url(../assets/news_phone.jpg);
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;

    .banner_text {
      width: 385px;
      height: 200px;
      text-align: center;

      .banner_title {
        font-size: 48px;
      }

      .banner_content {
        font-size: 20px;
        color: #333333
      }

      .line {
        position: relative;
        top: 2%;
        left: 44%;
        width: 30px;
        height: 2px;
        border-radius: 0px 0px 0px 0px;
        opacity: 1;
        // 线条粗细
        border: 1px solid black;
        margin: 24px 0px;
      }

    }
  }

  .text-muted {
    cursor: pointer;
  }
}

/* PC端样式 */
@media screen and (min-width: 768px) {
  .news {
    height: 1625px;
  }

  .card-img-top {
    width: 350px;
    height: 150px;
  }

  .banner {
    width: 100%;
    height: 600px;
    background-image: url(../assets/news_banner.jpg);
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;

    .banner_text {
      width: 385px;
      height: 200px;
      text-align: center;

      .banner_title {
        font-size: 48px;
      }

      .banner_content {
        font-size: 20px;
        color: #333333;
      }

      .line {
        position: relative;
        top: 2%;
        left: 44%;
        width: 30px;
        height: 2px;
        border-radius: 0px 0px 0px 0px;
        opacity: 1;
        // 线条粗细
        border: 1px solid black;
        margin: 24px 0px;
      }

    }
  }

  .card {
    cursor: pointer;
  }

  .card-body {
    background-color: #F4F4F4;

    .card-title {
      height: 60px;
    }
  }

  .card_date {
    position: relative;

    .date {
      width: 62px;
      height: 60px;
      background-color: #fff;
      text-align: center;
      position: absolute;
      bottom: 1px;
      left: 0;
    }
  }

  .text-muted {
    cursor: pointer;
  }
}
</style >